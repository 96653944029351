import {Component, enableProdMode, HostListener, OnDestroy} from '@angular/core';

import {AlertController, ModalController, Platform} from '@ionic/angular';
import {WatchingService} from './core/services/watching.service';
import {UsersService} from './core/services/users.service';
import {AuthenticationService} from './core/services/authentication.service';
import {StorageService} from './core/services/storage.service';
import {PwaService} from './core/services/pwa.service';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {InstallPromptComponent} from './components/install-prompt/install-prompt.component';
import {Router} from '@angular/router';
import {EmergencyAlertService} from "./core/services/emergency-alert.service";
import {environment} from "../environments/environment";

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnDestroy {
    lastUrl = '/tabs/home';
    interval;
    KEY_IOS_INSTALL_PROMPT = "KEY_IOS_INSTALL_PROMPT";

    constructor(
        private platform: Platform,
        private router: Router,
        private watchingService: WatchingService, // pre load watch list
        private userService: UsersService,
        private storageService: StorageService,
        private authService: AuthenticationService,
        private pwaService: PwaService, // Import service to trigger update check
        private fireMessaging: AngularFireMessaging,
        private modalCtrl: ModalController,
        private alertCtrl: AlertController,
        private emergencyAlertService: EmergencyAlertService
    ) {
        this.initializeApp();
        fireMessaging.messages.subscribe(payload => {
            console.log(payload);
        });
    }

    initializeApp() {
        this.platform.ready().then(() => {

            this.pwaService.checkForUpdate();
            this.emergencyAlertService.check();
            this.interval = setInterval(() => {
                this.pwaService.checkForUpdate();
            }, (1000 * 60 * 10));

            this.authService.$isAuthenticated.subscribe(val => {
                if (val) {
                    this.requestNotificationPermissionAndToken();
                }
            });
            const prompts = parseInt(localStorage.getItem(this.KEY_IOS_INSTALL_PROMPT), 10);
            console.log("prompts", prompts);
            if (!prompts || (prompts < 10 && prompts % 3 == 0)) {
                this.promptIosAddHomeScreen();
            } else if (prompts > 10 && prompts < 50 && prompts % 5 == 0) {
                this.promptIosAddHomeScreen();
            }
            localStorage.setItem(this.KEY_IOS_INSTALL_PROMPT, String(prompts ? prompts + 1 : 1));
        });
    }

    // listenForFailedRouteActivate() {
    //     this.router.events.subscribe(val => {
    //         console.log(val);
    //         if (val instanceof GuardsCheckEnd) {
    //             if (!val.shouldActivate) {
    //
    //                 //todo
    //                 console.log("open login modal here");
    //             }
    //         }
    //     });
    // }

    requestNotificationPermissionAndToken() {
        // Request token also requests permission if not accepted
        this.fireMessaging.requestToken.subscribe(
            (token) => {
                console.log('FBMCT', token);
                if (token) {
                    this.storageService.setFbCmt(token);
                    this.userService.setFbCmt(token, this.storageService.getClientId());
                }
            },
            (err) => {
                console.error(err);
            }
        );

    }

    private async promptIosAddHomeScreen() {
        if (this.platform.is('ios')) {
            // @ts-ignore
            const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator.standalone);
            if (!isInStandaloneMode) {
                const modal = await this.modalCtrl.create({
                    component: InstallPromptComponent,
                    cssClass: 'ios-install-prompt-modal'
                });

                await modal.present();

                setTimeout(() => {
                    modal.dismiss();
                }, 2700);
            }
        }
    }


    @HostListener('window:popstate', ['$event'])
    onPopState() {
        this.modalCtrl.getTop()
            .then(async modal => {
                if (modal) {
                    await modal.dismiss();
                } else {
                    // await this.router.navigateByUrl(this.lastUrl, {skipLocationChange: true});
                }
            });
    }

    // handleBackButtonEvent() {
    //     this.router.events.pipe(
    //         filter(e => e instanceof RoutesRecognized),
    //         pairwise(),
    //     )
    //         .subscribe((event: any[]) => {
    //             this.lastUrl = (event[0].urlAfterRedirects);
    //             console.log("Last url", this.lastUrl);
    //         });
    // }

    async presentAlertConfirm() {
        const alert = await this.alertCtrl.create({
            // header: 'Confirm!',
            message: 'Are you sure you want to exit?',
            buttons: [{
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                handler: (blah) => {
                }
            }, {
                text: 'Exit',
                handler: () => {
                    (navigator as any).app.exitApp();
                }
            }]
        });

        await alert.present();
    }

    ngOnDestroy() {
        clearInterval(this.interval);
    }
}
