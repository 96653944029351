
import {FIREBASE_STORAGE_ROOT, FIREBASE_STORAGE_TAIL} from "../../../assets/api/api-urls";
import {IItem} from "../models/item.model";

export class ImageParserService {
    public static parseImageURLs(data: IItem[] | IItem): IItem[] | IItem {
        if (data == null) return null;
        if (Array.isArray(data)) {
            for (const item of data) {
                item.images = this.getImageURL(item.images);
            }
            return data as IItem[];
        } else {
            data.images = this.getImageURL(data.images);
        }
        return data as IItem;
    }

    public static getSingleUrl(filename: String) {
        if (filename) {
            return FIREBASE_STORAGE_ROOT + filename + FIREBASE_STORAGE_TAIL;
        } else {
            return null;
        }
    }

    private static getImageURL(images): string[] {
        images = JSON.parse(images);
        if (images == null) return null;
        for (let i = 0; i < images.length; i++) {
            images[i] = FIREBASE_STORAGE_ROOT + images[i] + FIREBASE_STORAGE_TAIL;
        }
        return images;
    }
}
