import {Injectable} from '@angular/core';
import {
    GET_FIND_USER,
    POST_UPDATE_USER_AVATAR,
    GET_USERNAME_IN_USE,
    GET_CLIENT_ID,
    POST_SAVE_USER,
    POST_FB_CMT, POST_EMAIL_IN_USE, GET_FIND_PUBLIC_USER,
    GET_USER_LOCATION_ID
} from '../../../assets/api/api-urls';
import {IPublicUser, IUser} from '../models/user.model';
import {FailedResponseHandlerService} from './failedResponseHandler.service';
import {StorageService} from './storage.service';
import {BehaviorSubject} from 'rxjs';
import {GetRetryService} from './getRetry.service';
import {PostRetryService} from './postRetry.service';
import {ImageParserService} from './imageParser.service';
import {LocationService} from './location.service';

@Injectable({
    providedIn: 'root'
})


export class UsersService {
    lastUser: IUser = {};

    constructor(private failedResponseHandler: FailedResponseHandlerService,
                private storageService: StorageService,
                private getRetry: GetRetryService,
                private postRetry: PostRetryService,
                private locationService: LocationService
    ) {}

    public async fetchUserByID(retries: number, id: number): Promise<any> {
        const URL = GET_FIND_USER + '/' + id;
        const obData = new BehaviorSubject<any>('init');
        return await new Promise(((resolve, reject) => {
            obData.subscribe(val => {
                if (val && val !== 'init') {
                    this.lastUser = val;
                    this.lastUser.avatar = ImageParserService.getSingleUrl(this.lastUser.avatar);
                    resolve(this.lastUser as IUser);
                } else if (!val) {
                    reject();
                }
            });
            this.getRetry.getJsonWithRetry(retries, URL, obData);
        }));
    }

    public async fetchPublicUserByID(retries: number, id: number): Promise<any> {
        const URL = GET_FIND_PUBLIC_USER + '/' + id;
        const obData = new BehaviorSubject<any>('init');
        return await new Promise(((resolve, reject) => {
            obData.subscribe(val => {
                if (val && val !== 'init') {
                    this.lastUser = val;
                    this.lastUser.avatar = ImageParserService.getSingleUrl(this.lastUser.avatar);
                    resolve(this.lastUser as IPublicUser);
                } else if (!val) {
                    reject();
                }
            });
            this.getRetry.getJsonWithRetry(retries, URL, obData);
        }));
    }

    public async updateUserAvatar(user: IUser) {
        const obData = new BehaviorSubject<any>('init');

        return await new Promise((resolve, reject) => {
            obData.subscribe(val => {
                if (val && val !== 'init') {
                    resolve();
                } else if (!val) {
                    reject();
                }
            });
            this.postRetry.postJsonWithRetry(3, POST_UPDATE_USER_AVATAR, user, obData);
        });
    }

    public async checkUsernameInUse(nameToCheck: string, nameInUseObserver: BehaviorSubject<boolean>) {
        const url = GET_USERNAME_IN_USE + '/' + nameToCheck;
        const obData = new BehaviorSubject<any>('init');
        obData.subscribe(val => {
                if (val !== 'init') {
                    nameInUseObserver.next(val);
                }
            });
        await this.getRetry.getJsonWithRetry(3, url, obData);
    }

    public async checkEmailInUse(emailToCheck: string, emailInUseObserver: BehaviorSubject<boolean>) {
        const url = POST_EMAIL_IN_USE;
        const obData = new BehaviorSubject<any>('init');
        obData.subscribe(val => {
            if (val !== 'init') {
                emailInUseObserver.next(val);
            }
        });
        await this.postRetry.postJsonWithRetry(3, url, emailToCheck, obData);
    }

    public async getClientId(retries: number) {
        return new Promise(async (resolve) => {
            await fetch(GET_CLIENT_ID, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: this.storageService.getFirebaseToken()
                }
            }).then(res => {

                if (res.ok && res.status === 200) {
                    res.text().then(data => {
                        this.storageService.setClientId(data);
                        resolve(data);
                    });
                } else {
                    // todo what to do if fail to get clientId? Check if authenticated then force relogin or error toast?

                    // this.failedResponseHandler.handle(res);
                    // this.retry(retries, this.getClientId, [retries - 1]);
                }
            }).catch(err => {
                console.error(err);
                if (retries > 0) {
                    retries--;
                    setTimeout(() => {
                        this.getClientId(retries);
                    }, 3000 / retries);
                }
            });
        });

    }

    public async insertUser(u: IUser) {
        return await new Promise((resolve, reject) => {
            fetch(POST_SAVE_USER, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(u)
            })
                .then(res => {
                    if (res.ok) {
                        res.text()
                            .then(success => {
                                try {
                                   const clientId = parseInt(success, 10);
                                   resolve(clientId);
                                } catch (e) {
                                    console.error(e);
                                    reject();
                                }
                            })
                            .catch(err => {
                                console.error(err);
                                reject();
                            });
                    }
                })
                .catch(err => {
                    console.error(err);
                    reject();
                });
        });
    }

    public async setFbCmt(token: string, id: number) {
        const URL = POST_FB_CMT + '/' + id;
        await this.postRetry.postJsonWithRetry(3, URL, token, new BehaviorSubject<any>(null));
    }

    public  async getAndSaveUserLocation(id) {
        const URL = GET_USER_LOCATION_ID  + '/' + id;
        const obData = new BehaviorSubject<any>('init');
        obData.subscribe(val => {
            if (val !== 'init') {
                this.locationService.saveLocationById(val);
            }
        });
        await this.getRetry.getJsonWithRetry(3, URL, obData);
    }

}
