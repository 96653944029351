export const ENGLISH_STRINGS = {
    // GLOBAL
    title: 'Magpie',
    home: 'Home',
    location: 'My Location',
    my_account: 'My Account',
    log_out: 'Log out',
    log_in: 'Log in',
    email_address: 'Email Address',
    password: 'Password',
    confirm_password: 'Confirm password',
    username: 'Username',
    create_account: 'Create account',
    logged_in: 'Logged in!',
    logged_out: 'Logged out!',
    location_set_to: 'Location set to:',
    items_in: 'Items in:',
    availability: 'Availability',
    item_available: 'Available',
    item_reserved: 'Reserved',
    item_sold: 'Sold',
    contact: 'Contact',
    cancel: 'Cancel',
    close: 'Close',
    done: 'Done',
    open_gallery: 'Open Gallery',
    ok: 'OK',

    // DASHBOARD + CHILDREN
    my_dashboard: 'My dashboard',
    my_magpie: 'My Magpie',
    selling: 'Selling',
    watching: 'Watching',
    messages: 'Messages',
    delete_message: 'Delete',
    reply_message: 'Reply',
    selling_pageTitle_new: 'Sell new item',
    selling_pageTitle_edit: 'Edit item',
    selling_title: 'Title',
    selling_category: 'Category',
    selling_price: 'Price',
    selling_description: 'Description',
    selling_post: 'Post',
    selling_update: 'Update',
    selling_delete: 'Delete',
    my_items: 'My Items',
    my_watched_items: 'My Watched Items',


    // Toasts
    toast_too_many_images_selected: 'Too many images selected!',
    is_own_item: 'This is your own item!',
    new_message: 'new message',
    new_messages: 'new messages',
    login_error: 'Error logging in!',
    network_error: 'Network error. Check your internet connection.',
    invalid_email: 'Invalid email address',
    unknown_email: 'Email address unknown',
    incorrect_password: 'Incorrect password',
    success: 'Success!',
    went_wrong: 'Something went wrong.',
    server_error: 'Server returned error ',
    service_unavailable: 'Service unavailable',

    //Home page
    is_error_loading_items: 'Error loading items...',
    is_no_search_results: 'No results.',
    location_nationwide: 'Nationwide',
    filter_include_sold: 'Include sold items',
    filter_posted_after: 'Posted after...',
    filter_select_all: 'Select all',

    //Post page + children
    photo_picker_title: 'Pick Photos',
    location_select: 'Location',
    thumbnail: 'Thumbnail',
    upload_images: 'Uploading images...',
    posting: 'Posting...',
    post_error: 'Error posting item. Please try again later.',

    // Login
    reset_email_invalid: 'Provided email is invalid',
    reset_email_user_not_found: 'No account found for this email',
    reset_email_sent: 'Reset email sent!',
    too_many_failed_attempts: 'Too many failed attempts. Account temporarily disabled',

    // Sign up
    username_taken: 'Username taken',
    email_in_use: 'Email in use by another account',
    name: 'Name',
    button_reset_password: 'Send password reset email',
    email_verify_alert_header: 'Verify email',
    email_verify_alert_message: 'Please check your email and follow the verification link. Please click the \'Ok\' button AFTER you have verified your email',
    email_verify_resend_button: 'Resend email',


    // Profile & feedback
    feedback: 'Feedback',
    button_submit: 'Submit',
    overall: 'Overall experience',
    positive: 'Positive',
    negative: 'Negative',
    feedback_alert_message: 'Are you sure you mean to give 0 stars?',
    positive_comments :[
        "Friendly",
        "Polite",
        "Showed up on time",
        "Helpful",
        "Quick transaction",
        "Quick responses",
        "Item as described",
        "Fair negotiation"
    ],
    negative_comments: [
        "Cancelled offer",
        "Rude",
        "Didn't show up",
        "Unhelpful",
        "Unresponsive",
        "Item not as described",
        "Slow transaction",
        "Arrived late"
    ],
    no_positive_feedback: 'This user hasn\'t received any positive feedback yet',
    no_negative_feedback: 'This user hasn\'t received any negative feedback yet',

    //Reports
    report: "Report",
    report_reason: "Please select a reason...",
    report_info: "Additional information...",
    item_reports: [
        "Illegal or fraudulent",
        "Post is an ad or spam",
        "Post is duplicate",
        "Post in wrong category",
        "Post violates rules",
        "Other",
    ],
    user_reports: [
        "Inappropriate profile picture",
        "Harassment",
        "User is scammer",
        "Other",
    ],

    //Alerts
    update_available: 'A new update is available',
    button_update: 'Update now',
    yes: 'Yes',
    header_reset_email: 'Send password reset email?',
    message_reset_email: 'Are you sure you want to reset the password for ',
};

export const VALIDATION_MESSAGES_LOGIN = {
    username: [
        {type: 'required', message: 'Username is required.'},
        {type: 'minlength', message: 'Must be at least 5 characters.'},
        {type: 'maxlength', message: 'Cannot be more than 25 characters.'},
        {type: 'pattern', message: 'Must contain letters, numbers or \'-\' or \'_\''},
        {type: 'invalidUsername', message: 'Username taken.'}
    ],
    email: [
        {type: 'required', message: 'Email is required.'},
        {type: 'pattern', message: 'Please enter a valid email.'}
    ],
    password: [
        {type: 'required', message: 'Password is required.'},
        {type: 'minlength', message: 'Must be at least 5 characters long.'},
        {type: 'pattern', message: 'Must contain at least one uppercase, one lowercase, and one number.'}
    ],
    confirm_password: [
        {type: 'required', message: 'Please confirm your password.'},
        {type: 'areeual', message: 'Passwords do not match.'}
    ],
    name: [
        {type: 'required', message: 'Please provide your name.'},
        {type: 'minlength', message: 'Too short.'}
    ],
    location: [
        {type: 'required', message: 'Please provide your location.'}
    ]
};
