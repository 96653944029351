// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyDSoI3YyGmwLP87MxkyULPwxsynDWS9aS4",
    authDomain: "magpie-market.firebaseapp.com",
    databaseURL: "https://magpie-market.firebaseio.com",
    projectId: "magpie-market",
    storageBucket: "magpie-market.appspot.com",
    messagingSenderId: "814770132401",
    appId: "1:814770132401:web:2d5fb319a30854ef2c9990",
    measurementId: "G-SY7PXPDE1B"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
