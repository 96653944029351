import {Injectable} from '@angular/core';
import {ENGLISH_STRINGS} from '../../../assets/locale-strings/english';

@Injectable()
export class LanguageProvider {

    KEY_LANGUAGE = 'KEY_LANGUAGE';
    VAL_LANGUAGE = '';

    constructor() {
        this.VAL_LANGUAGE = localStorage.getItem(this.KEY_LANGUAGE);
    }

    getLocaleStrings() {
        switch (this.VAL_LANGUAGE) {
            case 'cn':
                return ''; // todo
            case 'kr':
                return ''; // todo
            default:
                return ENGLISH_STRINGS;
        }
    }
}
