import {ApplicationRef, Injectable} from "@angular/core";
import {SwUpdate} from "@angular/service-worker";
import {AlertController, Platform} from "@ionic/angular";
import {LanguageProvider} from "./languageProvider";
import {StorageService} from "./storage.service";

@Injectable({
    providedIn: "root"
})
export class PwaService {

    STRINGS: any;

    constructor(private appRef: ApplicationRef,
                private swUpdate: SwUpdate,
                private alertCtrl: AlertController,
                private platform: Platform,
                private storageService: StorageService,
                languageProvider: LanguageProvider) {
        this.STRINGS = languageProvider.getLocaleStrings();


        swUpdate.available.subscribe(next => {
            if (next) {
                console.log("Update: " + next);
                this.presentUpdateAlert();
            }
        });
    }

    public checkForUpdate() {
        this.swUpdate.checkForUpdate();
    }

    async presentUpdateAlert() {
        const alert = await this.alertCtrl.create({
            header: this.STRINGS.update_available,
            buttons: [
                {
                    text: this.STRINGS.button_update,
                    handler: () => {
                        this.clearCache();
                        // todo - change to location.href = page url (if user reloads on messages or item breaks page)
                        window.location.reload(true);
                    }
                }
            ]
        });
        return await alert.present();
    }

    clearCache() {
        if ('caches' in window) {
            caches.keys()
                .then(function (keyList) {
                    return Promise.all(keyList.map(function (key) {
                        return caches.delete(key);
                    }));
                })
        }
    }

}
