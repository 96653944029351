import {Injectable} from "@angular/core";
import {StorageService} from "./storage.service";
import {FailedResponseHandlerService} from "./failedResponseHandler.service";
import {BehaviorSubject} from "rxjs";
import {GenericToast} from "../../components/toasts/generic-toast.component";
import {LanguageProvider} from "./languageProvider";

@Injectable({
    providedIn: 'root'
})
export class PostRetryService {
    STRINGS: any;

    constructor(private storageService: StorageService,
                private failedResponseHandler: FailedResponseHandlerService,
                private genericToast: GenericToast,
                languageProvider: LanguageProvider) {
        this.STRINGS = languageProvider.getLocaleStrings();
    }

    public async postJsonWithRetry(retries: number, url: string, bodyData, observer: BehaviorSubject<any>) {
        await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Accept': '*/*',
                'Authorization': this.storageService.getFirebaseToken()
            },
            body: typeof bodyData === 'string' ? bodyData : JSON.stringify(bodyData)
        })
            .then(res => {
                if (res.ok && res.status === 200) {
                    if (res.headers.get('content-type').indexOf('application/json') > -1) {
                        res.json()
                            .then(data => {
                                observer.next(data);
                            })
                            .catch(err => {
                                console.error(err);
                                observer.next(false);
                            });
                    } else {
                        res.text()
                            .then(data => {
                                observer.next(data);
                            })
                            .catch(err => {
                                console.error(err);
                                observer.next(false);
                            });
                    }
                } else {
                    const callBack = this.retry;
                    const callBackArgs = [retries, this.postJsonWithRetry, [retries - 1, url, bodyData, observer, this]];
                    this.failedResponseHandler.handle(res, callBack, callBackArgs);
                }
            })
            .catch(err => {
                console.error(err);
                if (retries > 0) {
                    this.retry(retries, this.postJsonWithRetry, [retries - 1, url, observer]);
                } else {
                    this.genericToast.presentGenericToast(this.STRINGS.network_error);
                    observer.next(false);
                }
            });
    }


    private retry(retries: number, func: Function, args: any[]) {
        if (retries > 0) {
            setTimeout(() => {
                func.apply(args[4], args);
            }, 3000 / retries);
        } else {
            args[3].next(false);
        }
    }
}
