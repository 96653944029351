import {ToastController} from '@ionic/angular';
import {Injectable} from '@angular/core';
import {ToastButton} from '@ionic/core/dist/types/components/toast/toast-interface.d';

@Injectable()

export class GenericToast {
    constructor(private toastCtrl: ToastController) {
    }

    async presentGenericToast(messageText: string) {
        const toast = await this.toastCtrl.create({
            message: messageText,
            duration: 2000
        });
        toast.present();
    }

    async presentGenericToastWithButtons(messageText: string, _buttons: ToastButton[]) {
        const toast = await this.toastCtrl.create({
            message: messageText,
            duration: 3000,
            buttons: _buttons
        });
        toast.present();
    }
}
