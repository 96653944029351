import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'location',
    loadChildren: () => import('./location/location.module').then( m => m.LocationPageModule)
  },
  {
    path: 'item-view',
    loadChildren: () => import('./components/item-view/item-view.module').then( m => m.ItemViewPageModule)
  },
  {
    path: 'thread-viewer',
    loadChildren: () => import('./components/messages/thread-viewer/thread-viewer.module').then(m => m.ThreadViewerPageModule)
  },
  {
    path: 'messages',
    loadChildren: () => import('./components/messages/messages.module').then(m => m.MessagesPageModule),
  },
  {
    path: 'post',
    loadChildren: () => import('./post/post.module').then( m => m.PostPageModule),
  },
  {
    path: 'my-magpie',
    loadChildren: () => import('./my-magpie/my-magpie.module').then( m => m.MyMagpiePageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'profile-view',
    loadChildren: () => import('./profile-view/profile-view.module').then( m => m.ProfileViewPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
