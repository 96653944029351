import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {ModalController} from "@ionic/angular";
import {GenericToast} from "../../components/toasts/generic-toast.component";
import {LanguageProvider} from "./languageProvider";
import {AngularFireAuth} from "@angular/fire/auth";

@Injectable({
    providedIn: 'root'
})
export class FailedResponseHandlerService {

    STRINGS: any;

    constructor(private router: Router,
                private modalCtrl: ModalController,
                private genericToast: GenericToast,
                languageProvider: LanguageProvider,
                private fireAuth: AngularFireAuth) {
        this.STRINGS = languageProvider.getLocaleStrings()
    }

    public handle(response: Response, callback: Function, args: any[]) {
        switch (response.status) {
            case 403 :
                this.handleForbidden(callback, args);
                break;
            case 503:
                callback.apply(args[args.length-1], args);
                break;
            case 504:
                this.genericToast.presentGenericToast(this.STRINGS.service_unavailable);
            default:
                this.genericToast.presentGenericToast(this.STRINGS.server_error + response.status);
        }
    }

    failedLoginHandler(err): boolean {
        if (err.code === "auth/network-request-failed") {
            this.genericToast.presentGenericToast(this.STRINGS.network_error);
            return true;
        } else if (err.code === "auth/invalid-email") {
            this.genericToast.presentGenericToast(this.STRINGS.invalid_email);
            return true;
        } else if (err.code === "auth/user-not-found") {
            this.genericToast.presentGenericToast(this.STRINGS.unknown_email);
            return true;
        } else if (err.code === "auth/wrong-password") {
            this.genericToast.presentGenericToast(this.STRINGS.incorrect_password);
            return true;
        } else if (err.code === "auth/email-already-in-use") {
            this.genericToast.presentGenericToast(this.STRINGS.email_in_use)
            return true;
        } else if (err.code === 'auth/too-many-requests') {
            this.genericToast.presentGenericToast(this.STRINGS.too_many_failed_attempts);
            return true;
        }
        return false;
    }

    failedResetEmailHandler(err) {
        switch (err.code) {
            case 'auth/invalid-email':
                this.genericToast.presentGenericToast(this.STRINGS.reset_email_invalid);
                break;
            case 'auth/user-not-found':
                this.genericToast.presentGenericToast(this.STRINGS.reset_email_user_not_found);
                break;
        }
    }

    private handleForbidden(callback, args) {
        // If retries remaining...
        if (args[0] > 0) {
            // Force token refresh
            this.fireAuth.currentUser
                .then(user => {
                    user.getIdToken(true)
                        .then(() => {
                            // If token refreshes try request again
                            callback.apply(args);
                        })
                        .catch(() => {
                            // If token fails to refresh redirect to login
                            this.navLogin();
                        });
                })
        } else {
            //If no retries remaining nav back to home
            this.genericToast.presentGenericToast(this.STRINGS.server_403);
            this.navHome();
        }
    }

    private navLogin() {
        this.router.navigateByUrl('/tabs/login');
    }

    private navHome() {
        this.router.navigateByUrl('/tabs/home');
    }
}
