// const ROOT_URL = 'http://localhost:8082/';
// const ROOT_URL = 'http://10.215.232.203:8081/';
// const ROOT_URL = 'http://10.8.0.2:8081/';
// const ROOT_URL = 'https://srv2.luves2spooge.co.uk:8081/';
const ROOT_URL = 'https://api.magpiemarket.kr/';

export const GET_ALL_ITEMS = ROOT_URL + 'cust/products';
export const GET_ALL_ITEMS_BY_LOCATION = ROOT_URL + 'items/location';
export const GET_ALL_ITEMS_BY_USER = ROOT_URL + 'items/allFromUser'
export const GET_ALL_CATEGORIES = ROOT_URL + 'category/all';
export const GET_ALL_LOCATIONS = ROOT_URL + 'locations/locations';
export const GET_SEARCH_ITEM = ROOT_URL + 'items/advancedSearch';

export const GET_FIND_USER = ROOT_URL + 'users/user';
export const GET_FIND_PUBLIC_USER = ROOT_URL + 'users/publicUser';
export const POST_UPDATE_USER = ROOT_URL + 'users/update';
export const POST_UPDATE_USER_AVATAR = ROOT_URL + 'users/updateAvatar';
export const GET_USERNAME_IN_USE = ROOT_URL + 'users/usernameInUse';
export const POST_EMAIL_IN_USE = ROOT_URL + 'users/emailInUse';
export const GET_CLIENT_ID = ROOT_URL + 'users/getClientId';
export const POST_SAVE_USER = ROOT_URL + 'users/save';
export const POST_FB_CMT = ROOT_URL + 'users/setFbCmt';
export const GET_USER_LOCATION_ID = ROOT_URL + 'users/getClientLocation';

export const GET_MESSAGE_THREAD = ROOT_URL + 'messages/thread';
export const GET_TOTAL_UNREAD_MESSAGES = ROOT_URL + 'messages/unread';
export const GET_ALL_MESSAGES = ROOT_URL + 'messages/all';
export const POST_UPDATE_READ_FLAG = ROOT_URL + 'messages/updateReadFlags';
export const POST_SEND_MESSAGE = ROOT_URL + 'messages/insertMessage';

export const POST_WATCH_ADD = ROOT_URL + 'watching/add';
export const POST_WATCH_DELETE = ROOT_URL + 'watching/delete';
export const GET_WATCH_ITEM_TOTAL = ROOT_URL + 'watching/itemTotal';
export const GET_WATCH_LIST = ROOT_URL + 'watching/watchList';

export const POST_ITEM = ROOT_URL + 'items/insert';
export const POST_UPDATE_ITEM = ROOT_URL + 'items/update'; //todo - test

export const POST_FEEDBACK = ROOT_URL + 'feedback/insert';
export const GET_FEEDBACK = ROOT_URL + 'feedback/findOne';
export const POST_UPDATE_FEEDBACK = ROOT_URL + 'feedback/update';
export const POST_FEEDBACK_LOG_REQUEST = ROOT_URL + 'feedback/findLog';

export const POST_INSERT_REPORT = ROOT_URL + 'reports/insert';

export const AVATAR_ROOT = '../../../../../IdeaProjects/magpie-backend-spring-boot/'; // todo
export const FIREBASE_STORAGE_ROOT = 'https://firebasestorage.googleapis.com/v0/b/magpie-market.appspot.com/o/';
export const FIREBASE_STORAGE_TAIL = '?alt=media';

export const GET_VERSION = ROOT_URL + 'version/get';
