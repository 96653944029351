import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LanguageProvider} from './core/services/languageProvider';
import {LocationService} from './core/services/location.service';
import {GenericToast} from "./components/toasts/generic-toast.component";
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import {CurrencyPipe} from "@angular/common";
import {CurrencyService} from "./core/services/currency.service";
import {AngularFireAuthModule} from "@angular/fire/auth";
import {AngularFireModule} from "@angular/fire";
import {AngularFireMessagingModule} from "@angular/fire/messaging";
import {FormBuilder} from "@angular/forms";
import {InstallPromptComponent} from "./components/install-prompt/install-prompt.component";

@NgModule({
    declarations: [AppComponent, InstallPromptComponent],
    entryComponents: [],
    imports: [BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        AngularFireMessagingModule,
        ServiceWorkerModule.register('combined-sw.js', {enabled: environment.production})],
    providers: [
        StatusBar,
        SplashScreen,
        LanguageProvider,
        LocationService,
        GenericToast,
        CurrencyPipe,
        CurrencyService,
        FormBuilder,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy}
    ],
    exports: [

    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
