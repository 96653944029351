import {Injectable} from '@angular/core';
import {GET_ALL_LOCATIONS} from '../../../assets/api/api-urls';
import {ILocationModel} from '../models/location.model';
export  const KEY_LOC_ID = 'KEY_LOC_ID';
export const KEY_LOC_NAME = 'KEY_LOC_NAME';

@Injectable({providedIn: 'root'})
export class LocationService {

    private allLocations: ILocationModel[] = [{name: 'Nationwide', id: -1}];

    constructor() {
    }

    public async matchCityToId(city: string): Promise<any> {
        if (this.allLocations.length === 0) {
            this.fetchAllLocations();
        }
        return new Promise((resolve, reject) => {
            for (const loc of this.allLocations) {
                if (loc.name.toLowerCase() === city.toLowerCase()) {
                    resolve(loc);
                }
            }
            reject();
        });
    }

    public async matchIdToCity(id: number): Promise<string> {
        const n = function(allLocs: ILocationModel[], id: number) {
            for (const l of allLocs) {
                if (l.id === id) {
                    return l.name;
                }
            }
        };

        return new Promise((resolve, reject) => {
            if (this.allLocations.length === 1) {
                this.fetchAllLocations()
                    .then(allLocs => {
                        const name = n(allLocs, id);
                        if (name) {
                            resolve(name);
                        } else {
                            reject();
                        }
                    });
            } else {
                const name = n(this.allLocations, id);
                if (name) {
                    resolve(name);
                } else {
                    reject();
                }
            }
        });
    }

    public async fetchAllLocations(): Promise<ILocationModel[]> {
        return new Promise(((resolve, reject) => {
            if (this.allLocations.length > 1) {
                resolve(this.allLocations);
            } else {
                fetch(GET_ALL_LOCATIONS)
                    .then(res => {
                        if (res.ok && res.status === 200) {
                            res.json()
                                .then(data => {
                                    this.allLocations = data;
                                    resolve(this.allLocations);
                                })
                                .catch(err => {
                                    console.error(err);
                                    reject();
                                });
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        reject();
                    });
            }

        }));
    }


    public async getSavedLocation(): Promise<ILocationModel> {
        const location: ILocationModel = {id: null, name: null};
        return await new Promise<ILocationModel>((resolve, reject) => {
            const val = localStorage.getItem(KEY_LOC_ID);
            if (val === undefined || val === null || val.length === 0) {
                reject();
            } else {
                location.id = parseInt(val, 10);
                const val2 = localStorage.getItem(KEY_LOC_NAME);
                if (val2 === undefined || val2 === null || val2.length === 0) {
                    reject();
                } else {
                    location.name = val2;
                    resolve(location);
                }
            }
        });
    }

    public async saveLocation(loc: ILocationModel): Promise<boolean> {
        return await new Promise<boolean>(async resolve => {
            localStorage.setItem(KEY_LOC_ID, loc.id.toString());
            localStorage.setItem(KEY_LOC_NAME, loc.name.toString());
            resolve(true);
        });
    }

    public async clearLocation() {
        return await new Promise<boolean>(async resolve => {
            localStorage.setItem(KEY_LOC_ID, null);
            localStorage.setItem(KEY_LOC_NAME, null);
            resolve(true);
        });
    }

    public async saveLocationById(id: number) {
        const name = await this.matchIdToCity(id);
        await this.saveLocation({name, id});
    }

}
