import {Injectable} from '@angular/core';
import {CurrencyPipe} from "@angular/common";

@Injectable({
    providedIn: 'root',
})

export class CurrencyService {

    CURRENCY_FORMAT_KOREAN = 'KRW';
    SYMBOL_WON = '\u20A9';

    constructor(private currencyPipe: CurrencyPipe) {
    }


    formatPriceString(val: number | string, style: string): string {
        if (typeof val === 'number') {
            val = val.toString();
        }
        if (!val.match(/^\d/)) {
            val = val.substring(1, val.length);
        }

        val = val.replace(/,/g, '');
        return this.currencyPipe.transform(val, 'KRW');
    }

    manualFormatKRW(val: string) {

    }

}
