import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {StorageService} from './storage.service';
import {FailedResponseHandlerService} from './failedResponseHandler.service';
import {GenericToast} from '../../components/toasts/generic-toast.component';
import {LanguageProvider} from './languageProvider';
import {AngularFireAuth} from '@angular/fire/auth';

@Injectable({
    providedIn: 'root'
})
export class GetRetryService {

    STRINGS: any;
    fbt: string;

    constructor(private storageService: StorageService,
                private failedResponseHandler: FailedResponseHandlerService,
                private genericToast: GenericToast,
                languageProvider: LanguageProvider,
                private fireAuth: AngularFireAuth) {
        this.STRINGS = languageProvider.getLocaleStrings();
    }

    public async getJsonWithRetry(retries: number, url: string, observer: BehaviorSubject<any>) {
        await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: this.storageService.getFirebaseToken()
            },
        })
            .then(res => {
                if (res.ok && res.status === 200) {
                    res.json()
                        .then(data => {
                            observer.next(data);
                        })
                        .catch(err => {
                            console.error(err);
                            observer.next(false);
                        });
                } else if (res.ok) {
                    observer.next(false);
                } else {
                    const callBack = this.retry;
                    const callBackArgs = [retries, this.getJsonWithRetry, [retries - 1, url, observer, this]];
                    this.failedResponseHandler.handle(res, callBack, callBackArgs);
                }
            })
            .catch(err => {
                console.error(err);
                if (retries > 0) {
                    this.retry(retries, this.getJsonWithRetry, [retries - 1, url, observer]);
                } else {
                    observer.next(false);
                    this.genericToast.presentGenericToast(this.STRINGS.network_error);
                }
            });
    }

    public async getTextWithRetry(retries: number, url: string, observer: BehaviorSubject<any>) {
        await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: this.storageService.getFirebaseToken()
            }
        })
            .then(res => {
                if (res.ok && res.status === 200) {
                    res.text()
                        .then(data => {
                            observer.next(data);
                        })
                        .catch(err => {
                            console.error(err);
                        });
                }
            })
            .catch(err => {
                if (retries > 0) {
                    this.retry(retries, this.getJsonWithRetry, [retries - 1, url, observer, this]);
                } else {
                    observer.next(false);
                }
            });
    }

    private retry(retries: number, func: Function, args: any[]) {
        if (retries > 0) {
            setTimeout(() => {
                func.apply(args[3], args);
            }, 3000 / retries);
        } else {
            args[2].next(false);
        }
    }
}
