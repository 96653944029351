import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';

export const KEY_CLIENT_ID = 'client_id';
export const KEY_FIREBASE_ID = 'firebase_id';
export const KEY_FIREBASE_TOKEN_EXPIRES = 'firebase_token_expire';
export const KEY_REDIRECT_AFTER_LOGIN_TO = 'redirect_after_login_to';
export const KEY_AUTH_STATE = 'auth_state';
export const KEY_FIREBASE_CMT = 'fb_cm_token';
export const KEY_VERSION = 'version';
import {KEY_LOC_NAME, KEY_LOC_ID} from './location.service';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    fbt_ttl = 100 * 60 * 60;

    constructor(private fireAuth: AngularFireAuth) {
    }

    public setClientId(id) {
        console.log('Setting clientID as: ', id);
        localStorage.setItem(KEY_CLIENT_ID, id.toString());
    }

    public getClientId(): number {
        return parseInt(localStorage.getItem(KEY_CLIENT_ID), 10);
    }

    public clearClientId() {
        localStorage.removeItem(KEY_CLIENT_ID);
    }

    public setFirebaseToken(id: string) {
        this.setFirebaseTokenExpiry();
        localStorage.setItem(KEY_FIREBASE_ID, id);
    }

    public getFirebaseToken(): string {
        if (this.isFirebaseTokenExpired()) {
            this.fireAuth.user.subscribe(user => {
                user.getIdToken()
                    .then(fbt => {
                        this.setFirebaseToken(fbt);
                    });
            });
        }
        return localStorage.getItem(KEY_FIREBASE_ID);
    }

    public clearFireBaseToken() {
        localStorage.removeItem(KEY_FIREBASE_ID);
    }

    private setFirebaseTokenExpiry() {
        localStorage.setItem(KEY_FIREBASE_TOKEN_EXPIRES, String(new Date().getTime() + this.fbt_ttl));
    }

    private isFirebaseTokenExpired(): boolean {
        const expires = parseInt(localStorage.getItem(KEY_FIREBASE_TOKEN_EXPIRES), 10);
        return new Date().getTime() > expires;
    }

    // tabs-routing.module sets this key as well (no injection of storage.service)
    public setRedirectAfterLoginRoute( redirectAfterTo) {
        localStorage.setItem(KEY_REDIRECT_AFTER_LOGIN_TO, redirectAfterTo);
    }

    public getRedirectAfterLoginRoute(): string {
        const route = localStorage.getItem(KEY_REDIRECT_AFTER_LOGIN_TO);
        localStorage.removeItem(KEY_REDIRECT_AFTER_LOGIN_TO);
        return route;
    }

    public setAuthState(val: boolean) {
        localStorage.setItem(KEY_AUTH_STATE, val ? 'yes' : 'no');
    }

    public getAuthState(): boolean {
        return localStorage.getItem(KEY_AUTH_STATE) === 'yes';
    }

    public getFbCmt(): string {
        return localStorage.getItem(KEY_FIREBASE_CMT);
    }

    public setFbCmt(token: string): void {
        localStorage.setItem(KEY_FIREBASE_CMT, token);
    }

    public getVersion(): string {
        return localStorage.getItem(KEY_VERSION);
    }

    public setVersion(version: string) {
        localStorage.setItem(KEY_VERSION, version);
    }

    public clearLocation() {
        localStorage.removeItem(KEY_LOC_ID);
        localStorage.removeItem(KEY_LOC_NAME);
    }

}
