import { Injectable } from '@angular/core';
import {AlertController} from "@ionic/angular";

interface EmergencyResponse {
  isEmergency: boolean;
  forceQuit: true;
  title: string;
  body: string;
  date: string;

}

@Injectable({
  providedIn: 'root'
})

export class EmergencyAlertService {

  EMERGENCY_URL: 'http://alexanderjs.co.uk/emergency_alerts/magpie.json';
  responseJSON: EmergencyResponse;
  KEY_DONT_SHOW_DATE = 'KEY_DONT_SHOW_EMERGENCY_RESPONSE';

  constructor(private alertCtrl: AlertController) { }

  public check() {
    fetch(this.EMERGENCY_URL)
        .then(res => {
          if (res.ok && res.status === 200) {
            res.json()
                .then(val => {
                  this.responseJSON = val;
                  this.prepareAlert();
                });
          }
        });
  }

  private async prepareAlert() {
    const alert = await this.alertCtrl.create({
      header: this.responseJSON.title,
      subHeader: this.responseJSON.date,
      message: this.responseJSON.body,
      buttons: [{
        text: 'OK',
        role: 'cancel',
        handler: () => {
          if (this.responseJSON.forceQuit) {
            window.close();
          }
        }
      }]
    });
  }
}
