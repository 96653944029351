import {Component, Inject, OnInit} from '@angular/core';

@Component({
  selector: 'app-install-prompt',
  templateUrl: './install-prompt.component.html',
  styleUrls: ['./install-prompt.component.scss'],
})
export class InstallPromptComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
