import {Injectable} from '@angular/core';
import {
    POST_WATCH_ADD,
    POST_WATCH_DELETE,
    GET_WATCH_ITEM_TOTAL,
    GET_WATCH_LIST,
    FIREBASE_STORAGE_ROOT, FIREBASE_STORAGE_TAIL
} from '../../../assets/api/api-urls';
import {IItem} from '../models/item.model';
import {GenericToast} from '../../components/toasts/generic-toast.component';
import {LanguageProvider} from './languageProvider';
import {AuthenticationService} from './authentication.service';
import {StorageService} from './storage.service';
import {PostRetryService} from "./postRetry.service";
import {GetRetryService} from "./getRetry.service";
import {BehaviorSubject} from "rxjs";
import {IFeedbackLog} from "../models/feedback.model";


@Injectable({
    providedIn: 'root'
})

export class WatchingService {


    public watchedItems: IItem[] = [];
    clientId: number;
    STRINGS: any;

    constructor(languageProvider: LanguageProvider,
                private toast: GenericToast,
                private storageService: StorageService,
                private postRetry: PostRetryService,
                private getRetry: GetRetryService
                ) {
        this.clientId = storageService.getClientId();
        if (this.clientId) {
            this.getWatchList(this.clientId);
        }
        this.STRINGS = languageProvider.getLocaleStrings();
    }

    public async toggle(item: IItem): Promise<any> {
        if (item.user_id === this.clientId) {
            await this.toast.presentGenericToast(this.STRINGS.is_own_item);
            return;
        }
        for (const i of this.watchedItems) {
            if (i.id === item.id) {
                return await this.delete(item.id);
            }
        }
        return await this.add(item.id);
    }

    public async add(itemId: number) {
        const obData = new BehaviorSubject('init');
        const URL = POST_WATCH_ADD + '/' + itemId + '/' + this.clientId;
        return await new Promise(async resolve => {
            obData.subscribe(async next => {
                if (next && next != 'init') {
                    // @ts-ignore
                    if (next == 1) {
                        resolve();
                    }
                }
            });

            await this.postRetry.postJsonWithRetry(3, URL, null, obData);
        });

    }

    public async delete(itemId: number) { // todo - error handling
        const URL = POST_WATCH_DELETE + '/' + itemId + '/' + this.clientId;
        const obData = new BehaviorSubject('init');
        return await new Promise(async resolve => {
            obData.subscribe(async next => {
                if (next && next != 'init') {
                    // @ts-ignore
                    if (next == 1) {
                        resolve();
                    }
                }
            });
            await this.postRetry.postJsonWithRetry(3, URL, null, obData);
        });



    }

    // public async itemTotal(itemId: number): Promise<number> {
    //     const URL = GET_WATCH_ITEM_TOTAL + '/' + itemId;
    //     return await new Promise<number>((resolve, reject) => {
    //         fetch(URL)
    //             .then(res => {
    //                 res.json()
    //                     .then(data => {
    //                         resolve(data as number);
    //                     })
    //                     .catch(err => reject(err));
    //             })
    //             .catch(err => reject(err));
    //     });
    // }

    public async getWatchList(userId: number): Promise<IItem[]> {
        this.clientId = (userId === null) ? this.clientId : userId;
        const URL = GET_WATCH_LIST + '/' + this.clientId;

        const obData = new BehaviorSubject<any>('init');
        return await new Promise(async resolve => {
            obData.subscribe(async next => {
                if (next && next != 'init') {
                    // @ts-ignore
                    if (next) {
                        this.watchedItems = this.parseImageURLs(next);
                        console.log(this.watchedItems);
                        resolve(next as IItem[]);
                    }
                }
            });
            await this.getRetry.getJsonWithRetry(3, URL, obData);
        });





        // return await new Promise<IItem[]>((resolve, reject) => {
        //
        //     fetch(URL)
        //         .then(res => {
        //             res.json()
        //                 .then(data => {
        //                     this.watchedItems = this.parseImageURLs(data);
        //                     console.log(this.watchedItems);
        //                     resolve(this.watchedItems);
        //                 })
        //                 .catch(err => reject(err));
        //         })
        //         .catch(err => reject(err));
        // });
    }

    public isWatching(itemId: number): boolean {
        for (const item of this.watchedItems) {
            if (item.id === itemId) {
                return true;
            }
        }
        return false;
    }

    parseImageURLs(data): IItem[] {
        const parsedData: IItem[] = [];
        if (data) {
            for (const i of data) {
                const filenames: string[] = JSON.parse(i.images);
                i.images = [];
                if (filenames) {
                    for (const fn of filenames) {
                        i.images.push(this.getImageURL(fn));
                    }
                    parsedData.push(i);
                } else {
                    parsedData.push(i);
                }
            }
        }
        return parsedData;
    }

    getImageURL(filename): string {
        return FIREBASE_STORAGE_ROOT + filename + FIREBASE_STORAGE_TAIL;
    }
}
