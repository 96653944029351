import {Injectable,} from '@angular/core';
import {AlertController, Platform} from '@ionic/angular';
import {LanguageProvider} from './languageProvider';
import {GenericToast} from '../../components/toasts/generic-toast.component';
import {AngularFireAuth} from '@angular/fire/auth';
import {FailedResponseHandlerService} from './failedResponseHandler.service';
import {UsersService} from './users.service';
import {Router} from '@angular/router';
import {StorageService} from './storage.service';
import firebase from 'firebase';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

const KEY_TOKEN = 'auth-token';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    STRINGS: any;
    clientId: null;
    bearerToken;
    emailVerifyRetries = 3;
    $isAuthenticated: BehaviorSubject<any> = new BehaviorSubject<any>('init');

    constructor(private plt: Platform,
                private genericToast: GenericToast,
                languageProvider: LanguageProvider,
                private fireAuth: AngularFireAuth,
                private failedResponseHandler: FailedResponseHandlerService,
                private userService: UsersService,
                private router: Router,
                private storageService: StorageService,
                private alertCtrl: AlertController
    ) {
        this.plt.ready().then(async () => {
            this.fireAuth.idToken.subscribe(next => {
                this.bearerToken = next;
                this.storageService.setFirebaseToken(this.bearerToken);
            });


            this.fireAuth.user.subscribe(async user => {
                if (user && !user.emailVerified) {
                    user.sendEmailVerification()
                        .then(() => {
                            this.presentVerifyEmailAlert(false, user);
                        });
                }

                if (user) {
                    const id = await this.userService.getClientId(3);
                    this.userService.getAndSaveUserLocation(id);
                } else {
                    this.storageService.clearFireBaseToken();
                    this.storageService.clearClientId();
                    this.storageService.clearLocation();
                }
                this.storageService.setAuthState(user !== null);
                this.$isAuthenticated.next(user !== null);
            });
        });

        this.STRINGS = languageProvider.getLocaleStrings();
    }

    async login(email: string, password: string) {
        this.fireAuth.signInWithEmailAndPassword(email, password)
            .then(res => {
                if (res.user) {
                    // todo
                    // todo nav back to origin
                    this.presentLoggedInToast();
                    this.handleRedirect();
                } else {
                    this.presentFailedLoginToast();
                }
            })
            .catch(err => {
                console.error(err);
                if (!this.failedResponseHandler.failedLoginHandler(err)) {
                    this.presentFailedLoginToast();
                }
            });
    }

    async logout() {
        await this.fireAuth.signOut();
        await this.genericToast.presentGenericToast(this.STRINGS.logged_out);
        localStorage.removeItem(KEY_TOKEN);
        this.storageService.clearClientId();
        this.storageService.clearFireBaseToken();
        this.storageService.clearLocation();
    }

    public navToLoginPage() {
        this.router.navigateByUrl('tabs/login');
    }

    private async presentFailedLoginToast() {
        await this.genericToast.presentGenericToast(this.STRINGS.login_error);
    }

    private async presentLoggedInToast() {
        await this.genericToast.presentGenericToast(this.STRINGS.logged_in);
    }

    public handleRedirect() {
        let route = this.storageService.getRedirectAfterLoginRoute();
        route = route === null ? 'home' : route;
        if (route.includes('Route')) {
            route = route.match(/'(.*?[^\\])'/)[1];
        }
        this.router.navigateByUrl('tabs/' + route);
    }

    private async presentVerifyEmailAlert(showResend: boolean, user) {
        const ok = {
            text: 'Ok',
            handler: () => {
                this.confirmEmailVerified(user);
            }
        };

        const resend = {
            text: this.STRINGS.email_verify_resend_button,
            handler: () => {
                this.fireAuth.currentUser.then(user => user.sendEmailVerification());
            }
        };

        const buttons = showResend ? [ok, resend] : [ok];

        const mAlert = await this.alertCtrl.create({
            header: this.STRINGS.email_verify_alert_header,
            message: this.STRINGS.email_verify_alert_message,
            buttons
        });
        await mAlert.present();
    }

    private confirmEmailVerified(user: firebase.User) {
        user.reload()
            .then(async () => {
                console.log(user);
                if (user.emailVerified) {
                    this.router.navigate(['tabs/home']);
                }
                if (!user.emailVerified && this.emailVerifyRetries > 0) {
                    this.emailVerifyRetries--;
                    await this.presentVerifyEmailAlert(true, user);
                } else if (this.emailVerifyRetries === 0) {
                    await this.fireAuth.signOut();
                }
            });
    }

    public async resetPassword(email: string) {
        this.fireAuth.sendPasswordResetEmail(email)
            .then(() => {
                this.genericToast.presentGenericToast(this.STRINGS.reset_email_sent);
            })
            .catch(err => {
                console.error(err);
                this.failedResponseHandler.failedResetEmailHandler(err);
            });
    }
}
